export const AUTH_LOAD = 'AUTH_LOAD';
export const AUTH_LOAD_SUCCEEDED = 'AUTH_LOAD_SUCCEEDED';
export const AUTH_LOAD_FAILED = 'AUTH_LOAD_FAILED';
export const AUTH_WITH_EMAIL = 'AUTH_WITH_EMAIL';
export const AUTH_WITH_EMAIL_SUCCEEDED = 'AUTH_WITH_EMAIL_SUCCEEDED';
export const AUTH_WITH_EMAIL_FAILED = 'AUTH_WITH_EMAIL_FAILED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const AUTH_FEDERATE = 'AUTH_FEDERATE';
export const AUTH_FEDERATE_SUCCEEDED = 'AUTH_FEDERATE_SUCCEEDED';
export const AUTH_FEDERATE_FAILED = 'AUTH_FEDERATE_FAILED';

interface AuthFederate {
  type: typeof AUTH_FEDERATE;
}

interface AuthFederateSucceeded {
  type: typeof AUTH_FEDERATE_SUCCEEDED;
}

interface AuthFederateFailed {
  type: typeof AUTH_FEDERATE_FAILED;
  payload: {
    error: Error;
  };
}
interface AuthWithEmail {
  type: typeof AUTH_WITH_EMAIL;
}

interface AuthWithEmailSucceeded {
  type: typeof AUTH_WITH_EMAIL_SUCCEEDED;
}

interface AuthWithEmailFailed {
  type: typeof AUTH_WITH_EMAIL_FAILED;
  payload: {
    error: Error;
  };
}
interface AuthLoad {
  type: typeof AUTH_LOAD;
}

interface AuthLoadSucceeded {
  type: typeof AUTH_LOAD_SUCCEEDED;
  payload: {
    authenticated: boolean;
  };
}

interface AuthLoadFailed {
  type: typeof AUTH_LOAD_FAILED;
  payload: {
    error: Error;
  };
}
interface Logout {
  type: typeof LOGOUT;
}

interface LogoutSucceeded {
  type: typeof LOGOUT_SUCCEEDED;
}

interface LogoutFailed {
  type: typeof LOGOUT_FAILED;
  payload: {
    error: Error;
  };
}

export type AuthActionTypes =
  | AuthFederate
  | AuthFederateSucceeded
  | AuthFederateFailed
  | AuthWithEmail
  | AuthWithEmailSucceeded
  | AuthWithEmailFailed
  | AuthLoad
  | AuthLoadSucceeded
  | AuthLoadFailed
  | Logout
  | LogoutSucceeded
  | LogoutFailed;

export function authFederate(): AuthActionTypes {
  return {
    type: AUTH_FEDERATE,
  };
}

export function authFederateSucceeded(): AuthActionTypes {
  return {
    type: AUTH_FEDERATE_SUCCEEDED,
  };
}

export function authFederateFailed(error: Error): AuthActionTypes {
  return {
    type: AUTH_FEDERATE_FAILED,
    payload: {
      error,
    },
  };
}

export function authWithEmail(): AuthActionTypes {
  return {
    type: AUTH_WITH_EMAIL,
  };
}

export function authWithEmailSucceeded(): AuthActionTypes {
  return {
    type: AUTH_WITH_EMAIL_SUCCEEDED,
  };
}

export function authWithEmailFailed(error: Error): AuthActionTypes {
  return {
    type: AUTH_WITH_EMAIL_FAILED,
    payload: {
      error,
    },
  };
}

export function authLoad(): AuthActionTypes {
  return {
    type: AUTH_LOAD,
  };
}

export function authLoadSucceeded(authenticated: boolean): AuthActionTypes {
  return {
    type: AUTH_LOAD_SUCCEEDED,
    payload: {
      authenticated,
    },
  };
}

export function authLoadFailed(error: Error): AuthActionTypes {
  return {
    type: AUTH_LOAD_FAILED,
    payload: {
      error,
    },
  };
}

export function logout(): AuthActionTypes {
  return {
    type: LOGOUT,
  };
}

export function logoutSucceeded(): AuthActionTypes {
  return {
    type: LOGOUT_SUCCEEDED,
  };
}

export function logoutFailed(error: Error): AuthActionTypes {
  return {
    type: LOGOUT_FAILED,
    payload: {
      error,
    },
  };
}

import {
  ADD_USER_TO_WEEKLY_COHORT,
  ADD_USER_TO_WEEKLY_COHORT_FAILED,
  ADD_USER_TO_WEEKLY_COHORT_SUCCEEDED,
  GET_WEEKLY_COHORT,
  GET_WEEKLY_COHORTS,
  GET_WEEKLY_COHORTS_FAILED,
  GET_WEEKLY_COHORTS_SUCCEEDED,
  GET_WEEKLY_COHORT_FAILED,
  GET_WEEKLY_COHORT_SUCCEEDED,
  REMOVE_USER_FROM_WEEKLY_COHORT,
  REMOVE_USER_FROM_WEEKLY_COHORT_FAILED,
  REMOVE_USER_FROM_WEEKLY_COHORT_SUCCEEDED,
  WeeklyCohortActionTypes,
} from './action';
import { WeeklyCohort, Profile } from './types';

interface CohortState {
  currentWeeklyCohort?: WeeklyCohort;
  profiles: Profile[];
  weeklyCohorts: WeeklyCohort[];
  gettingWeeklyCohorts: boolean;
  gettingWeeklyCohort: boolean;
  addingUserToWeeklyCohort: boolean;
  removingUserFromWeeklyCohort: boolean;
}

export const initialState: CohortState = {
  currentWeeklyCohort: undefined,
  profiles: [],
  weeklyCohorts: [],
  //   gettingProfiles: false,
  gettingWeeklyCohorts: false,
  gettingWeeklyCohort: false,
  addingUserToWeeklyCohort: false,
  removingUserFromWeeklyCohort: false,
};

export const weeklyCohortReducer = (
  state = initialState,
  action: WeeklyCohortActionTypes,
): CohortState => {
  switch (action.type) {
    case GET_WEEKLY_COHORTS:
      return {
        ...state,
        gettingWeeklyCohorts: true,
      };
    case GET_WEEKLY_COHORTS_SUCCEEDED:
      return {
        ...state,
        weeklyCohorts: action.payload.weeklyCohorts,
        gettingWeeklyCohorts: false,
      };
    case GET_WEEKLY_COHORTS_FAILED:
      return {
        ...state,
        gettingWeeklyCohorts: false,
      };
    case GET_WEEKLY_COHORT:
      return {
        ...state,
        gettingWeeklyCohort: true,
      };
    case GET_WEEKLY_COHORT_SUCCEEDED:
      return {
        ...state,
        gettingWeeklyCohort: false,
        currentWeeklyCohort: action.payload.weeklyCohort,
        profiles: action.payload.weeklyCohort.profiles,
      };
    case GET_WEEKLY_COHORT_FAILED:
      return {
        ...state,
        gettingWeeklyCohort: false,
      };
    case ADD_USER_TO_WEEKLY_COHORT:
      return {
        ...state,
        addingUserToWeeklyCohort: true,
      };
    case ADD_USER_TO_WEEKLY_COHORT_SUCCEEDED:
    case ADD_USER_TO_WEEKLY_COHORT_FAILED:
      return {
        ...state,
        addingUserToWeeklyCohort: false,
      };
    case REMOVE_USER_FROM_WEEKLY_COHORT:
      return {
        ...state,
        removingUserFromWeeklyCohort: true,
      };
    case REMOVE_USER_FROM_WEEKLY_COHORT_SUCCEEDED:
    case REMOVE_USER_FROM_WEEKLY_COHORT_FAILED:
      return {
        ...state,
        removingUserFromWeeklyCohort: false,
      };
    // case GET_PROFILES:
    //   return {
    //     ...state,
    //     gettingProfiles: true,
    //   };
    // case GET_PROFILES_SUCCEEDED:
    //   return {
    //     ...state,
    //     gettingProfiles: false,
    //     profiles: action.payload.profiles,
    //   };
    // case GET_PROFILES_FAILED:
    //   return {
    //     ...state,
    //     gettingProfiles: false,
    //   };
    default:
      return state;
  }
};

import { Activity } from './type';

export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const GET_ACTIVITIES_SUCCEEDED = 'GET_ACTIVITIES_SUCCEEDED';
export const GET_ACTIVITIES_FAILED = 'GET_ACTIVITIES_FAILED';
export const GET_ACTIVITY = 'GET_ACTIVITY';
export const GET_ACTIVITY_SUCCEEDED = 'GET_ACTIVITY_SUCCEEDED';
export const GET_ACTIVITY_FAILED = 'GET_ACTIVITY_FAILED';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const UPDATE_ACTIVITY_SUCCEEDED = 'UPDATE_ACTIVITY_SUCCEEDED';
export const UPDATE_ACTIVITY_FAILED = 'UPDATE_ACTIVITY_FAILED';

interface GetActivities {
  type: typeof GET_ACTIVITIES;
}

interface GetActivitiesSucceeded {
  type: typeof GET_ACTIVITIES_SUCCEEDED;
  payload: {
    activities: Activity[];
  };
}

interface GetActivitiesFailed {
  type: typeof GET_ACTIVITIES_FAILED;
  payload: {
    error: Error;
  };
}

interface GetActivity {
  type: typeof GET_ACTIVITY;
}

interface GetActivitySucceeded {
  type: typeof GET_ACTIVITY_SUCCEEDED;
  payload: {
    activity: Activity;
  };
}

interface GetActivityFailed {
  type: typeof GET_ACTIVITY_FAILED;
  payload: {
    error: Error;
  };
}

interface UpdateActivity {
  type: typeof UPDATE_ACTIVITY;
}

interface UpdateActivitySucceeded {
  type: typeof UPDATE_ACTIVITY_SUCCEEDED;
}

interface UpdateActivityFailed {
  type: typeof UPDATE_ACTIVITY_FAILED;
  payload: {
    error: Error;
  };
}

export type ActivityActionType =
  | GetActivities
  | GetActivitiesSucceeded
  | GetActivitiesFailed
  | GetActivity
  | GetActivitySucceeded
  | GetActivityFailed
  | UpdateActivity
  | UpdateActivitySucceeded
  | UpdateActivityFailed;

export function getActivities(): ActivityActionType {
  return {
    type: GET_ACTIVITIES,
  };
}

export function getActivitiesSucceeded(
  activities: Activity[],
): ActivityActionType {
  return {
    type: GET_ACTIVITIES_SUCCEEDED,
    payload: {
      activities,
    },
  };
}

export function getActivitiesFailed(error: Error): ActivityActionType {
  return {
    type: GET_ACTIVITIES_FAILED,
    payload: {
      error,
    },
  };
}

export function getActivity(): ActivityActionType {
  return {
    type: GET_ACTIVITY,
  };
}

export function getActivitySucceeded(activity: Activity): ActivityActionType {
  return {
    type: GET_ACTIVITY_SUCCEEDED,
    payload: {
      activity,
    },
  };
}

export function getActivityFailed(error: Error): ActivityActionType {
  return {
    type: GET_ACTIVITY_FAILED,
    payload: {
      error,
    },
  };
}

export function updateActivity(): ActivityActionType {
  return {
    type: UPDATE_ACTIVITY,
  };
}

export function updateActivitySucceeded(): ActivityActionType {
  return {
    type: UPDATE_ACTIVITY_SUCCEEDED,
  };
}

export function updateActivityFailed(error: Error): ActivityActionType {
  return {
    type: UPDATE_ACTIVITY_FAILED,
    payload: {
      error,
    },
  };
}

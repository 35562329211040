import { Cohort, Profile } from './types';

export const GET_COHORTS = 'GET_COHORTS';
export const GET_COHORTS_SUCCEEDED = 'GET_COHORTS_SUCCEEDED';
export const GET_COHORTS_FAILED = 'GET_COHORTS_FAILED';
export const GET_COHORT = 'GET_COHORT';
export const GET_COHORT_SUCCEEDED = 'GET_COHORT_SUCCEEDED';
export const GET_COHORT_FAILED = 'GET_COHORT_FAILED';
export const UPDATE_COHORT = 'UPDATE_COHORT';
export const UPDATE_COHORT_SUCCEEDED = 'UPDATE_COHORT_SUCCEEDED';
export const UPDATE_COHORT_FAILED = 'UPDATE_COHORT_FAILED';
export const ADD_USER_TO_COHORT = 'ADD_USER_TO_COHORT';
export const ADD_USER_TO_COHORT_SUCCEEDED = 'ADD_USER_TO_COHORT_SUCCEEDED';
export const ADD_USER_TO_COHORT_FAILED = 'ADD_USER_TO_COHORT_FAILED';
export const REMOVE_USER_FROM_COHORT = 'REMOVE_USER_FROM_COHORT';
export const REMOVE_USER_FROM_COHORT_SUCCEEDED =
  'REMOVE_USER_FROM_COHORT_SUCCEEDED';
export const REMOVE_USER_FROM_COHORT_FAILED = 'REMOVE_USER_FROM_COHORT_FAILED';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_PROFILES_SUCCEEDED = 'GET_PROFILES_SUCCEEDED';
export const GET_PROFILES_FAILED = 'GET_PROFILES_FAILED';

interface GetCohorts {
  type: typeof GET_COHORTS;
}

interface GetCohortsSucceeded {
  type: typeof GET_COHORTS_SUCCEEDED;
  payload: {
    cohorts: Cohort[];
  };
}

interface GetCohortsFailed {
  type: typeof GET_COHORTS_FAILED;
  payload: {
    error: Error;
  };
}

interface GetCohort {
  type: typeof GET_COHORT;
}

interface GetCohortSucceeded {
  type: typeof GET_COHORT_SUCCEEDED;
  payload: {
    cohort: Cohort;
  };
}

interface GetCohortFailed {
  type: typeof GET_COHORT_FAILED;
  payload: {
    error: Error;
  };
}

interface UpdateCohort {
  type: typeof UPDATE_COHORT;
}

interface UpdateCohortSucceeded {
  type: typeof UPDATE_COHORT_SUCCEEDED;
}

interface UpdateCohortFailed {
  type: typeof UPDATE_COHORT_FAILED;
  payload: {
    error: Error;
  };
}

interface AddUserToCohort {
  type: typeof ADD_USER_TO_COHORT;
}

interface AddUserToCohortSucceeded {
  type: typeof ADD_USER_TO_COHORT_SUCCEEDED;
}

interface AddUserToCohortFailed {
  type: typeof ADD_USER_TO_COHORT_FAILED;
  payload: {
    error: Error;
  };
}

interface RemoveUserFromCohort {
  type: typeof REMOVE_USER_FROM_COHORT;
}

interface RemoveUserFromCohortSucceeded {
  type: typeof REMOVE_USER_FROM_COHORT_SUCCEEDED;
}

interface RemoveUserFromCohortFailed {
  type: typeof REMOVE_USER_FROM_COHORT_FAILED;
  payload: {
    error: Error;
  };
}

interface GetProfiles {
  type: typeof GET_PROFILES;
}

interface GetProfilesSucceeded {
  type: typeof GET_PROFILES_SUCCEEDED;
  payload: {
    profiles: Profile[];
  };
}

interface GetProfilesFailed {
  type: typeof GET_PROFILES_FAILED;
  payload: {
    error: Error;
  };
}

export type CohortActionTypes =
  | GetCohorts
  | GetCohortsSucceeded
  | GetCohortsFailed
  | GetCohort
  | GetCohortSucceeded
  | GetCohortFailed
  | UpdateCohort
  | UpdateCohortSucceeded
  | UpdateCohortFailed
  | AddUserToCohort
  | AddUserToCohortSucceeded
  | AddUserToCohortFailed
  | RemoveUserFromCohort
  | RemoveUserFromCohortSucceeded
  | RemoveUserFromCohortFailed
  | GetProfiles
  | GetProfilesSucceeded
  | GetProfilesFailed;

export function getCohorts(): CohortActionTypes {
  return {
    type: GET_COHORTS,
  };
}

export function getCohortsSucceeded(cohorts: Cohort[]): CohortActionTypes {
  return {
    type: GET_COHORTS_SUCCEEDED,
    payload: {
      cohorts,
    },
  };
}

export function getCohortsFailed(error: Error): CohortActionTypes {
  return {
    type: GET_COHORTS_FAILED,
    payload: {
      error,
    },
  };
}

export function getCohort(): CohortActionTypes {
  return {
    type: GET_COHORT,
  };
}

export function getCohortSucceeded(cohort: Cohort): CohortActionTypes {
  return {
    type: GET_COHORT_SUCCEEDED,
    payload: {
      cohort,
    },
  };
}

export function getCohortFailed(error: Error): CohortActionTypes {
  return {
    type: GET_COHORT_FAILED,
    payload: {
      error,
    },
  };
}

export function updateCohort(): CohortActionTypes {
  return {
    type: UPDATE_COHORT,
  };
}

export function updateCohortSucceeded(): CohortActionTypes {
  return {
    type: UPDATE_COHORT_SUCCEEDED,
  };
}

export function updateCohortFailed(error: Error): CohortActionTypes {
  return {
    type: UPDATE_COHORT_FAILED,
    payload: {
      error,
    },
  };
}

export function addUserToCohort(): CohortActionTypes {
  return {
    type: ADD_USER_TO_COHORT,
  };
}

export function addUserToCohortSucceeded(): CohortActionTypes {
  return {
    type: ADD_USER_TO_COHORT_SUCCEEDED,
  };
}

export function addUserToCohortFailed(error: Error): CohortActionTypes {
  return {
    type: ADD_USER_TO_COHORT_FAILED,
    payload: {
      error,
    },
  };
}

export function removeUserFromCohort(): CohortActionTypes {
  return {
    type: REMOVE_USER_FROM_COHORT,
  };
}

export function removeUserFromCohortSucceeded(): CohortActionTypes {
  return {
    type: REMOVE_USER_FROM_COHORT_SUCCEEDED,
  };
}

export function removeUserFromCohortFailed(error: Error): CohortActionTypes {
  return {
    type: REMOVE_USER_FROM_COHORT_FAILED,
    payload: {
      error,
    },
  };
}

export function getProfiles(): CohortActionTypes {
  return {
    type: GET_PROFILES,
  };
}

export function getProfilesSucceeded(profiles: Profile[]): CohortActionTypes {
  return {
    type: GET_PROFILES_SUCCEEDED,
    payload: {
      profiles,
    },
  };
}

export function getProfilesFailed(error: Error): CohortActionTypes {
  return {
    type: GET_PROFILES_FAILED,
    payload: {
      error,
    },
  };
}

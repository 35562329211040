import {
  ActivityActionType,
  GET_ACTIVITIES,
  GET_ACTIVITIES_FAILED,
  GET_ACTIVITIES_SUCCEEDED,
  GET_ACTIVITY,
  GET_ACTIVITY_FAILED,
  GET_ACTIVITY_SUCCEEDED,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_FAILED,
  UPDATE_ACTIVITY_SUCCEEDED,
} from './actions';
import { Activity } from './type';

interface ActivityState {
  activities: Activity[];
  gettingActivities: boolean;
  currentActivity?: Activity;
  gettingCurrentActivity: boolean;
  updatingCurrentActivity: boolean;
}

export const initialState: ActivityState = {
  activities: [],
  gettingActivities: false,
  currentActivity: undefined,
  gettingCurrentActivity: false,
  updatingCurrentActivity: false,
};

export const activityReducer = (
  state = initialState,
  action: ActivityActionType,
): ActivityState => {
  switch (action.type) {
    case GET_ACTIVITIES:
      return {
        ...state,
        gettingActivities: true,
      };
    case GET_ACTIVITIES_SUCCEEDED:
      return {
        ...state,
        activities: action.payload.activities,
        gettingActivities: false,
      };
    case GET_ACTIVITIES_FAILED:
      return {
        ...state,
        gettingActivities: false,
      };
    case GET_ACTIVITY:
      return {
        ...state,
        gettingCurrentActivity: true,
      };
    case GET_ACTIVITY_SUCCEEDED:
      return {
        ...state,
        gettingCurrentActivity: false,
        currentActivity: action.payload.activity,
      };
    case GET_ACTIVITY_FAILED:
      return {
        ...state,
        gettingCurrentActivity: false,
      };
    case UPDATE_ACTIVITY:
      return {
        ...state,
        updatingCurrentActivity: true,
      };
    case UPDATE_ACTIVITY_SUCCEEDED:
      return {
        ...state,
        updatingCurrentActivity: false,
      };
    case UPDATE_ACTIVITY_FAILED:
      return {
        ...state,
        updatingCurrentActivity: false,
      };
    default:
      return state;
  }
};

import dayjs from 'dayjs';
import {
  GET_USERS,
  GET_USERS_FAILED,
  GET_USERS_SUCCEEDED,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCEEDED,
  UsersActionType,
} from './actions';
import { User } from './type';

interface UsersState {
  users: User[];
  gettingUsers: boolean;
  currentUser?: User;
  updatingUsers: boolean;
}

export const initialState: UsersState = {
  users: [],
  gettingUsers: false,
  currentUser: undefined,
  updatingUsers: false,
};

export const usersReducer = (
  state = initialState,
  action: UsersActionType,
): UsersState => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        gettingUsers: true,
      };
    case GET_USERS_SUCCEEDED:
      return {
        ...state,
        users: action.payload.users.map((u) => ({
          ...u,
          createdAt: dayjs(u.createdAt),
        })),
        gettingUsers: false,
      };
    case GET_USERS_FAILED:
      return {
        ...state,
        gettingUsers: false,
      };

    case UPDATE_USER:
      return {
        ...state,
        updatingUsers: true,
      };
    case UPDATE_USER_SUCCEEDED:
    case UPDATE_USER_FAILED:
      return {
        ...state,
        updatingUsers: false,
      };

    default:
      return state;
  }
};

import { User } from './type';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCEEDED = 'GET_USERS_SUCCEEDED';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCEEDED = 'UPDATE_USER_SUCCEEDED';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

interface GetUsers {
  type: typeof GET_USERS;
}

interface GetUsersSucceeded {
  type: typeof GET_USERS_SUCCEEDED;
  payload: {
    users: User[];
  };
}

interface GetUsersFailed {
  type: typeof GET_USERS_FAILED;
}

interface UpdateUser {
  type: typeof UPDATE_USER;
}

interface UpdateUserSucceeded {
  type: typeof UPDATE_USER_SUCCEEDED;
}

interface UpdateUserFailed {
  type: typeof UPDATE_USER_FAILED;
}

export type UsersActionType =
  | GetUsers
  | GetUsersSucceeded
  | GetUsersFailed
  | UpdateUser
  | UpdateUserSucceeded
  | UpdateUserFailed;

export function getUsers(): UsersActionType {
  return {
    type: GET_USERS,
  };
}

export function getUsersSucceeded(users: User[]): UsersActionType {
  return {
    type: GET_USERS_SUCCEEDED,
    payload: {
      users,
    },
  };
}

export function getUsersFailed(): UsersActionType {
  return {
    type: GET_USERS_FAILED,
  };
}

export function updateUser(): UsersActionType {
  return {
    type: UPDATE_USER,
  };
}

export function updateUserSucceeded(): UsersActionType {
  return {
    type: UPDATE_USER_SUCCEEDED,
  };
}

export function updateUserFailed(): UsersActionType {
  return {
    type: UPDATE_USER_FAILED,
  };
}

import {
  ADD_USER_TO_COHORT,
  ADD_USER_TO_COHORT_FAILED,
  ADD_USER_TO_COHORT_SUCCEEDED,
  CohortActionTypes,
  GET_COHORT,
  GET_COHORTS,
  GET_COHORTS_FAILED,
  GET_COHORTS_SUCCEEDED,
  GET_COHORT_FAILED,
  GET_COHORT_SUCCEEDED,
  GET_PROFILES,
  GET_PROFILES_FAILED,
  GET_PROFILES_SUCCEEDED,
  REMOVE_USER_FROM_COHORT,
  REMOVE_USER_FROM_COHORT_FAILED,
  REMOVE_USER_FROM_COHORT_SUCCEEDED,
} from './action';
import { Cohort, Profile } from './types';

interface CohortState {
  currentCohort?: Cohort;
  profiles: Profile[];
  cohorts: Cohort[];
  gettingProfiles: boolean;
  gettingCohorts: boolean;
  gettingCohort: boolean;
  addingUserToCohort: boolean;
  removingUserFromCohort: boolean;
}

export const initialState: CohortState = {
  currentCohort: undefined,
  profiles: [],
  cohorts: [],
  gettingProfiles: false,
  gettingCohorts: false,
  gettingCohort: false,
  addingUserToCohort: false,
  removingUserFromCohort: false,
};

export const cohortReducer = (
  state = initialState,
  action: CohortActionTypes,
): CohortState => {
  switch (action.type) {
    case GET_COHORTS:
      return {
        ...state,
        gettingCohorts: true,
      };
    case GET_COHORTS_SUCCEEDED:
      return {
        ...state,
        cohorts: action.payload.cohorts,
        gettingCohorts: false,
      };
    case GET_COHORTS_FAILED:
      return {
        ...state,
        gettingCohorts: false,
      };
    case GET_COHORT:
      return {
        ...state,
        gettingCohort: true,
      };
    case GET_COHORT_SUCCEEDED:
      return {
        ...state,
        gettingCohort: false,
        currentCohort: action.payload.cohort,
      };
    case GET_COHORT_FAILED:
      return {
        ...state,
        gettingCohort: false,
      };
    case ADD_USER_TO_COHORT:
      return {
        ...state,
        addingUserToCohort: true,
      };
    case ADD_USER_TO_COHORT_SUCCEEDED:
    case ADD_USER_TO_COHORT_FAILED:
      return {
        ...state,
        addingUserToCohort: false,
      };
    case REMOVE_USER_FROM_COHORT:
      return {
        ...state,
        removingUserFromCohort: true,
      };
    case REMOVE_USER_FROM_COHORT_SUCCEEDED:
    case REMOVE_USER_FROM_COHORT_FAILED:
      return {
        ...state,
        removingUserFromCohort: false,
      };
    case GET_PROFILES:
      return {
        ...state,
        gettingProfiles: true,
      };
    case GET_PROFILES_SUCCEEDED:
      return {
        ...state,
        gettingProfiles: false,
        profiles: action.payload.profiles,
      };
    case GET_PROFILES_FAILED:
      return {
        ...state,
        gettingProfiles: false,
      };
    default:
      return state;
  }
};

import { WeeklyCohort } from './types';

export const GET_WEEKLY_COHORTS = 'GET_WEEKLY_COHORTS';
export const GET_WEEKLY_COHORTS_SUCCEEDED = 'GET_WEEKLY_COHORTS_SUCCEEDED';
export const GET_WEEKLY_COHORTS_FAILED = 'GET_WEEKLY_COHORTS_FAILED';
export const GET_WEEKLY_COHORT = 'GET_WEEKLY_COHORT';
export const GET_WEEKLY_COHORT_SUCCEEDED = 'GET_WEEKLY_COHORT_SUCCEEDED';
export const GET_WEEKLY_COHORT_FAILED = 'GET_WEEKLY_COHORT_FAILED';
export const UPDATE_WEEKLY_COHORT = 'UPDATE_WEEKLY_COHORT';
export const UPDATE_WEEKLY_COHORT_SUCCEEDED = 'UPDATE_WEEKLY_COHORT_SUCCEEDED';
export const UPDATE_WEEKLY_COHORT_FAILED = 'UPDATE_WEEKLY_COHORT_FAILED';
export const ADD_USER_TO_WEEKLY_COHORT = 'ADD_USER_TO_WEEKLY_COHORT';
export const ADD_USER_TO_WEEKLY_COHORT_SUCCEEDED =
  'ADD_USER_TO_WEEKLY_COHORT_SUCCEEDED';
export const ADD_USER_TO_WEEKLY_COHORT_FAILED =
  'ADD_USER_TO_WEEKLY_COHORT_FAILED';
export const REMOVE_USER_FROM_WEEKLY_COHORT = 'REMOVE_USER_FROM_WEEKLY_COHORT';
export const REMOVE_USER_FROM_WEEKLY_COHORT_SUCCEEDED =
  'REMOVE_USER_FROM_WEEKLY_COHORT_SUCCEEDED';
export const REMOVE_USER_FROM_WEEKLY_COHORT_FAILED =
  'REMOVE_USER_FROM_WEEKLY_COHORT_FAILED';
// export const GET_PROFILES = 'GET_PROFILES';
// export const GET_PROFILES_SUCCEEDED = 'GET_PROFILES_SUCCEEDED';
// export const GET_PROFILES_FAILED = 'GET_PROFILES_FAILED';

interface GetWeeklyCohorts {
  type: typeof GET_WEEKLY_COHORTS;
}

interface GetWeeklyCohortsSucceeded {
  type: typeof GET_WEEKLY_COHORTS_SUCCEEDED;
  payload: {
    weeklyCohorts: WeeklyCohort[];
  };
}

interface GetWeeklyCohortsFailed {
  type: typeof GET_WEEKLY_COHORTS_FAILED;
  payload: {
    error: Error;
  };
}

interface GetWeeklyCohort {
  type: typeof GET_WEEKLY_COHORT;
}

interface GetWeeklyCohortSucceeded {
  type: typeof GET_WEEKLY_COHORT_SUCCEEDED;
  payload: {
    weeklyCohort: WeeklyCohort;
  };
}

interface GetWeeklyCohortFailed {
  type: typeof GET_WEEKLY_COHORT_FAILED;
  payload: {
    error: Error;
  };
}

interface UpdateWeeklyCohort {
  type: typeof UPDATE_WEEKLY_COHORT;
}

interface UpdateWeeklyCohortSucceeded {
  type: typeof UPDATE_WEEKLY_COHORT_SUCCEEDED;
}

interface UpdateWeeklyCohortFailed {
  type: typeof UPDATE_WEEKLY_COHORT_FAILED;
  payload: {
    error: Error;
  };
}

interface AddUserToWeeklyCohort {
  type: typeof ADD_USER_TO_WEEKLY_COHORT;
}

interface AddUserToWeeklyCohortSucceeded {
  type: typeof ADD_USER_TO_WEEKLY_COHORT_SUCCEEDED;
}

interface AddUserToWeeklyCohortFailed {
  type: typeof ADD_USER_TO_WEEKLY_COHORT_FAILED;
  payload: {
    error: Error;
  };
}

interface RemoveUserFromWeeklyCohort {
  type: typeof REMOVE_USER_FROM_WEEKLY_COHORT;
}

interface RemoveUserFromWeeklyCohortSucceeded {
  type: typeof REMOVE_USER_FROM_WEEKLY_COHORT_SUCCEEDED;
}

interface RemoveUserFromWeeklyCohortFailed {
  type: typeof REMOVE_USER_FROM_WEEKLY_COHORT_FAILED;
  payload: {
    error: Error;
  };
}

// interface GetProfiles {
//   type: typeof GET_PROFILES;
// }

// interface GetProfilesSucceeded {
//   type: typeof GET_PROFILES_SUCCEEDED;
//   payload: {
//     profiles: Profile[];
//   };
// }

// interface GetProfilesFailed {
//   type: typeof GET_PROFILES_FAILED;
//   payload: {
//     error: Error;
//   };
// }

export type WeeklyCohortActionTypes =
  | GetWeeklyCohorts
  | GetWeeklyCohortsSucceeded
  | GetWeeklyCohortsFailed
  | GetWeeklyCohort
  | GetWeeklyCohortSucceeded
  | GetWeeklyCohortFailed
  | UpdateWeeklyCohort
  | UpdateWeeklyCohortSucceeded
  | UpdateWeeklyCohortFailed
  | AddUserToWeeklyCohort
  | AddUserToWeeklyCohortSucceeded
  | AddUserToWeeklyCohortFailed
  | RemoveUserFromWeeklyCohort
  | RemoveUserFromWeeklyCohortSucceeded
  | RemoveUserFromWeeklyCohortFailed;
//   | GetProfiles
//   | GetProfilesSucceeded
//   | GetProfilesFailed;

export function getWeeklyCohorts(): WeeklyCohortActionTypes {
  return {
    type: GET_WEEKLY_COHORTS,
  };
}

export function getWeeklyCohortsSucceeded(
  weeklyCohorts: WeeklyCohort[],
): WeeklyCohortActionTypes {
  return {
    type: GET_WEEKLY_COHORTS_SUCCEEDED,
    payload: {
      weeklyCohorts,
    },
  };
}

export function getWeeklyCohortsFailed(error: Error): WeeklyCohortActionTypes {
  return {
    type: GET_WEEKLY_COHORTS_FAILED,
    payload: {
      error,
    },
  };
}

export function getWeeklyCohort(): WeeklyCohortActionTypes {
  return {
    type: GET_WEEKLY_COHORT,
  };
}

export function getWeeklyCohortSucceeded(
  weeklyCohort: WeeklyCohort,
): WeeklyCohortActionTypes {
  return {
    type: GET_WEEKLY_COHORT_SUCCEEDED,
    payload: {
      weeklyCohort,
    },
  };
}

export function getWeeklyCohortFailed(error: Error): WeeklyCohortActionTypes {
  return {
    type: GET_WEEKLY_COHORT_FAILED,
    payload: {
      error,
    },
  };
}

export function updateWeeklyCohort(): WeeklyCohortActionTypes {
  return {
    type: UPDATE_WEEKLY_COHORT,
  };
}

export function updateWeeklyCohortSucceeded(): WeeklyCohortActionTypes {
  return {
    type: UPDATE_WEEKLY_COHORT_SUCCEEDED,
  };
}

export function updateWeeklyCohortFailed(
  error: Error,
): WeeklyCohortActionTypes {
  return {
    type: UPDATE_WEEKLY_COHORT_FAILED,
    payload: {
      error,
    },
  };
}

export function addUserToWeeklyCohort(): WeeklyCohortActionTypes {
  return {
    type: ADD_USER_TO_WEEKLY_COHORT,
  };
}

export function addUserToWeeklyCohortSucceeded(): WeeklyCohortActionTypes {
  return {
    type: ADD_USER_TO_WEEKLY_COHORT_SUCCEEDED,
  };
}

export function addUserToWeeklyCohortFailed(
  error: Error,
): WeeklyCohortActionTypes {
  return {
    type: ADD_USER_TO_WEEKLY_COHORT_FAILED,
    payload: {
      error,
    },
  };
}

export function removeUserFromWeeklyCohort(): WeeklyCohortActionTypes {
  return {
    type: REMOVE_USER_FROM_WEEKLY_COHORT,
  };
}

export function removeUserFromWeeklyCohortSucceeded(): WeeklyCohortActionTypes {
  return {
    type: REMOVE_USER_FROM_WEEKLY_COHORT_SUCCEEDED,
  };
}

export function removeUserFromWeeklyCohortFailed(
  error: Error,
): WeeklyCohortActionTypes {
  return {
    type: REMOVE_USER_FROM_WEEKLY_COHORT_FAILED,
    payload: {
      error,
    },
  };
}

// export function getProfiles(): WeeklyCohortActionTypes {
//   return {
//     type: GET_PROFILES,
//   };
// }

// export function getProfilesSucceeded(
//   profiles: Profile[],
// ): WeeklyCohortActionTypes {
//   return {
//     type: GET_PROFILES_SUCCEEDED,
//     payload: {
//       profiles,
//     },
//   };
// }

// export function getProfilesFailed(error: Error): WeeklyCohortActionTypes {
//   return {
//     type: GET_PROFILES_FAILED,
//     payload: {
//       error,
//     },
//   };
// }

import { combineReducers } from 'redux';
import { authReducer } from './auth/reducer';
import { activityReducer } from './dashboard/activities/reducer';
import { cohortReducer } from './dashboard/cohorts/reducer';
import { weeklyCohortReducer } from './dashboard/weeklyCohorts/reducer';
import { toasterReducer } from './toaster/reducer';
import { usersReducer } from './dashboard/users/reducer';

export const rootReducer = combineReducers({
  activities: activityReducer,
  auth: authReducer,
  toaster: toasterReducer,
  cohort: cohortReducer,
  weeklyCohort: weeklyCohortReducer,
  users: usersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
